<div class="row">
  <div class="col-md-6">
    <app-brief-viewer></app-brief-viewer>
    <router-outlet></router-outlet>
    <app-document-viewer></app-document-viewer>
  </div>
  <div class="col-md-6">
    <app-source-viewer></app-source-viewer>
  </div>
</div>
<ui-modal-list></ui-modal-list>
<ui-toast-list></ui-toast-list>
